import { useContext } from "react";
import { TransactionContext } from "../context/TransactionContext";

const WalletCard = () => {
    const { totalMined, totalStaked, totalAirdropped, stakedFees, reflectedFees, minedByUser } =
        useContext(TransactionContext);

    return (
        <div className="p-3 justify-end items-start flex-col rounded-xl w-72 my-5 bg-gray-700 text-white w-main">
            <div className="flex justify-between flex-col w-full h-full">
                <div className="text-sm">
                    <ul>
                        <li>
                            Total Mined: <span className="text-xs">{totalMined}</span>
                        </li>
                        <li>
                            Total Staked: <span className="text-xs">{totalStaked}</span>
                        </li>
                        <li>
                            Total Airdropped: <span className="text-xs">{totalAirdropped}</span>
                        </li>
                        <li>
                            Staked Fees: <span className="text-xs">{stakedFees}</span>
                        </li>
                        <li>
                            Reflected Fees: <span className="text-xs">{reflectedFees}</span>
                        </li>
                        <li>
                            Mined By User:<span className="text-xs">{minedByUser}</span>{" "}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
export default WalletCard;
