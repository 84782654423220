import React from "react";

const Profile = () => {
    return (
        <div>
            <main className="bg-gray-400 dark:bg-gray-700 md:w-main rounded-3xl p-2 text-md m-2 h-32"></main>
        </div>
    );
};

export default Profile;
