import { GiFireGem } from "react-icons/gi";
import { BsPiggyBankFill } from "react-icons/bs";
import { BsCashStack } from "react-icons/bs";
import { GiMiner } from "react-icons/gi";
import { RiRainyFill } from "react-icons/ri";

const ServiceCard = ({ color, title, icon, subtitle }) => (
    <div className="flex flex-row justify-start items-center white-glassmorphism p-3 m-2 cursor-pointer hover:shadow-xl ">
        <div className={`w-10 h-10 rounded-full flex justify-center items-center  ${color}`}>{icon}</div>
        <div className="ml-5 flex flex-col justify-center">
            <h1 className=" text-white text-lg">{title}</h1>
            <p className="mt-2 text-white text-sm ">{subtitle}</p>
        </div>
    </div>
);

const Services = () => {
    return (
        <div className="flex w-full justify-center items-center  flex-col mf:flex-row">
            <div className="flex mf:flex-row flex-col items-center justify-between p-20 py-12 px-4">
                <div className="flex flex-col justify-start items-start ">
                    <h1 className="text-white text-3xl sm:text-5xl py-2 text-gradient ">
                        What we provide
                        <br />
                        for our users
                    </h1>
                </div>
            </div>
            <div className="flex flex-col justify-start items-stretch pb-10 mf:py-6">
                <ServiceCard
                    color="bg-[#06649a]"
                    title="Safu Space"
                    icon={<BsPiggyBankFill fontSize={21} className="text-white" />}
                    subtitle="We are developing a safe place where to store funds is not only safe, but also profitable."
                />
                <ServiceCard
                    color="bg-[#06649a]"
                    title="Two-Tier Staking System"
                    icon={<BsCashStack fontSize={21} className="text-white" />}
                    subtitle="The unique two-tier staking system distributes 2% from every buy/sell to all holers and 2% to all stakers using the reflection methods."
                />
                <ServiceCard
                    color="bg-[#06649a]"
                    title="Proof-of-Trade Mining System"
                    icon={<GiMiner fontSize={21} className="text-white" />}
                    subtitle="Earn by buying, buy by earning: Built-in POT system adds a chance to get extra tokens from the mine when you buy it."
                />
                <ServiceCard
                    color="bg-[#06649a]"
                    title="Airdrop System"
                    icon={<RiRainyFill fontSize={21} className="text-white" />}
                    subtitle="The unique Airdrop system distributes a mine overflowing with tokens to all holders using the reflection method."
                />
                <ServiceCard
                    color="bg-[#06649a]"
                    title="Bi-flationary System"
                    icon={<GiFireGem fontSize={21} className="text-white" />}
                    subtitle="Creating and burning tokens works in automatic mode, which makes the token more stable during sharp jumps and drops in price."
                />
            </div>
        </div>
    );
};

export default Services;
