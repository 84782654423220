import React from "react";

const Roadmap = () => {
    return (
        <div className="text-md text-white flex  justify-center ">
            <div className="blue-glassmorphism md:my-36 mx-6 my-12 md:p-12 p-4">
                <h1>2021</h1>
                <br />
                <h2>Q3</h2>
                <ul className="list-disc list-inside w-full ">
                    <li>&#127765; development of a token contract for the ecosystem</li>
                    <li>&#127765; development of a detailed structure of the ecosystem</li>
                    <li>&#127765; creating profiles for the community</li>
                </ul>
                <br />
                <h2>Q4</h2>
                <ul className="list-disc list-inside">
                    <li>&#127765; launching and testing the contract for the token in the testnet, fixing bugs.</li>
                    <li>&#127765; launching a simple website.</li>
                </ul>
                <br />
                <h1>2022</h1>
                <br />
                <h2>Q1</h2>
                <ul className="list-disc list-inside">
                    <li>&#127763; development of a web3 application for the ecosystem</li>
                    <li>&#127762; web3 application testing</li>
                    <li>&#127761; preparation of documentation</li>
                    <li>&#127761; Preparation for marketing</li>
                </ul>
                <br />
                <h2>Q2</h2>
                <ul className="list-disc list-inside">
                    <li>&#127761; ecosystem presentation</li>
                    <li>&#127761; marketing</li>
                    <li>&#127761; launch of trading</li>
                </ul>
            </div>
        </div>
    );
};
export default Roadmap;
